import { theme } from "antd"
import { useSelector } from "react-redux";

const useStyles = () => {
  const { token } = theme.useToken();
  const tema = useSelector(state => state.general.tema);

  const algorithms = {
    0: 'default',
    1: 'dark',
    2: 'compact',
  };

  return {
    ...token,
    fontWeightLight: 300,
    headerItemHeight: 64,
    smallItemStyle: {
      width: "80px"
    },
    mediumItemStyle: {
      width: "220px"
    },
    bigItemStyle: {
      width: "300px"
    },
    algorithm: algorithms[tema],
  }
}

export default useStyles