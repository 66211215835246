import { useState } from "react";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Space, Typography } from "antd";
import ElegirFilialUsuario from "components/ElegirFilialUsuario";
import useStyles from "hooks/useStyles";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const UserMenu = () => {
  const { fontSizeLG, colorWhite, headerItemHeight } = useStyles();
  const [openFiliales, setOpenFiliales] = useState(false);
  const navigate = useNavigate();

  const usuario = useSelector(state => state.general.usuario);
  const nombre = usuario?.personal?.nombre;

  const items = [
    {
      icon: <SettingOutlined />,
      label: (
        <Link to="/configuracion">
          Configuración
        </Link>
      ),
      key: '/configuracion',
    }, {
      icon: <LogoutOutlined />,
      label: "Salir",
      key: 'salir',
      onClick: () => {
        if (usuario?.filiales?.length > 1) {
          setOpenFiliales(true);
        } else {
          navigate("/login");
        }
      }
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
      >
        <Button
          type="text"
          style={{
            fontSize: fontSizeLG,
            height: headerItemHeight,
            paddingLeft: 0,
          }}
        >
          <Space direction="horizontal" size="small" align="center">
            <Avatar size="default">
              {nombre[0]}
            </Avatar>
            <Typography.Text style={{ color: colorWhite }}>
              {nombre}
            </Typography.Text>
          </Space>
        </Button>
      </Dropdown>
      {openFiliales &&
        <ElegirFilialUsuario
          filiales={usuario?.filiales}
          usuarioId={usuario?.id}
          onClose={() => setOpenFiliales(false)}
        />
      }
    </>
  );
};

export default UserMenu;