import React from "react";
import { Suspense, lazy } from "react";
import { Outlet, createBrowserRouter, Navigate } from "react-router-dom";
import AppLayout from "./components/Layout/AppLayout";
import Loading from "components/Loading";
import { ENTERED_FROM_LOGIN } from "constants/auth";

const LoginPage = lazy(() => import("./routes/login"));
const NoLogin = lazy(() => import("./routes/clientes/Nuevo/NoLogin"));
const InicioPage = lazy(() => import("./routes/inicio"));
const Configuracion = lazy(() => import("./routes/configuracion"));
const ClientesPage = lazy(() => import("./routes/clientes"));
const ClientesEditarPage = lazy(() => import("./routes/clientes/Editar"));
const UsuariosPage = lazy(() => import("./routes/usuarios"));
const FilialesPage = lazy(() => import("./routes/filiales"));
const FilialesEditarPage = lazy(() => import("./routes/filialesEditar"));
const LocalidadesPage = lazy(() => import("./routes/localidades"));
const RolesPage = lazy(() => import("./routes/roles"));
const ArticulosPage = lazy(() => import("./routes/articulos"));
const ContratistasPage = lazy(() => import("./routes/contratistas"));
const VehiculosPage = lazy(() => import("./routes/vehiculos"));
const VehiculosTiposPage = lazy(() => import("./routes/vehiculosTipos"));
const PreciosAxelPage = lazy(() => import("./routes/preciosAxel"));
const PrecioDetallePage = lazy(() => import("./routes/precioDetalle"));
const SubrubrosPage = lazy(() => import("./routes/subrubros"));
const AcuerdosPage = lazy(() => import("./routes/acuerdos"));
const AcuerdoDetallePage = lazy(() => import("./routes/acuerdoDetalle"));
const RedespachantesPage = lazy(() => import("./routes/redespachantes"));
const RedespachantesPreciosPage = lazy(() => import("./routes/redespachantesPrecios"));
const RedespachantesPreciosPageOLD = lazy(() => import("./routes/redespachantesPrecios-old"));
const RedespachantesPrecioDetallePageOLD = lazy(() => import("./routes/redespachantesPrecioDetalle-old"));
const DocumentosPage = lazy(() => import("./routes/documentos"));
const DocumentoDetallePage = lazy(() => import("./routes/documentos/Editar"));
const DocumentacionFilialesPage = lazy(() => import("./routes/documentacionFiliales"));
const PreciosPage = lazy(() => import("./routes/precios"));
const PreciosEditarPage = lazy(() => import("./routes/precios/Editar"));
const TramosPage = lazy(() => import("./routes/tramos"));
const ComprobantesPage = lazy(() => import("./routes/comprobantes"));
const AutorizacionesPage = lazy(() => import("./routes/autorizaciones"));
const ContenedoresPage = lazy(() => import("./routes/contenedores"));
const LecturasPage = lazy(() => import("./routes/lecturas"));
const DistribuidoresPage = lazy(() => import("./routes/distribuidores"));
const VehiculosSalidasPage = lazy(() => import("./routes/vehiculosSalidas"));
const SeguimientoPage = lazy(() => import("./routes/seguimiento"));
const DepositosPage = lazy(() => import("./routes/depositos"));
const DistribucionesPage = lazy(() => import("./routes/distribuciones"));
const DistribucionesDetallePage = lazy(() => import("./routes/distribuciones/Detalle"));
const ProveedoresPage = lazy(() => import("./routes/proveedores"));

const ProtectedRoute = ({ children }) => {
  const enteredFromLogin = localStorage.getItem(ENTERED_FROM_LOGIN) === 'true';

  if (!enteredFromLogin) {
    return <Navigate to="/login" />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading fullscreen />}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: "/clientes/embed",
    element: <NoLogin />,
  },
  {
    path: "/usuario/:ualta/clientes/nuevo",
    element: <NoLogin />,
  },
  {
    path: "/usuario/:ualta/clientes/:clientes_id_speed/domicilio/nuevo",
    element: <NoLogin />,
  },
  {
    path: "/clientes/nuevo/:tokenParam",
    element: <NoLogin />,
  },
  {
    path: "/clientes/:id/domicilio/nuevo/:tokenParam",
    element: <NoLogin />,
  },
  {
    path: "/clientes/:id/domicilio/entrega/nuevo/:tokenParam",
    element: <NoLogin />,
  },
  {
    path: "/clientes/:id/domicilio/entrega/retiro/nuevo/:tokenParam",
    element: <NoLogin />,
  },
  {
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <InicioPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/configuracion",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <Configuracion />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/clientes",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ClientesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/clientes/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ClientesEditarPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/usuarios",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <UsuariosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/filiales",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <FilialesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/filiales/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <FilialesEditarPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/localidades",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <LocalidadesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/roles",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RolesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/articulos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ArticulosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/contratistas",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ContratistasPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/vehiculos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <VehiculosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/vehiculostipos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <VehiculosTiposPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/precios1",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <PreciosAxelPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/precios1/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <PrecioDetallePage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/subrubros",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <SubrubrosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/bonificaciones",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <AcuerdosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/bonificaciones/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <AcuerdoDetallePage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/redespachantes",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RedespachantesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/redespachantes/:id/precios",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RedespachantesPreciosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        ///redespachantes/:idredespachante/precios/:idredespachantesprecios
        path: "/redespachantes/:id/precios/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              {/* <RedespachantesPrecioDetallePage /> */}
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/redespachantes/:id/preciosold",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RedespachantesPreciosPageOLD />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/redespachantes/:idredespachante/preciosold",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RedespachantesPreciosPageOLD />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/redespachantes/:idredespachante/precios/:idredespachantespreciosold",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <RedespachantesPrecioDetallePageOLD />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/documentos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <DocumentosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/documentos/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <DocumentoDetallePage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/precios",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <PreciosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/precios/:id",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <PreciosEditarPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/filiales/documentacion",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <DocumentacionFilialesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/tramos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <TramosPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/comprobantes",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ComprobantesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/autorizaciones",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <AutorizacionesPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/contenedores/canastos",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ContenedoresPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/contenedores/bolsines",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ContenedoresPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/contenedores/colectoras",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <ContenedoresPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/lecturas",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <LecturasPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/distribuidores",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <DistribuidoresPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/vehiculos/salidas",
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading fullscreen />}>
              <VehiculosSalidasPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "/seguimiento",
        element: (
          <ProtectedRoute>
            <Suspense>
              <SeguimientoPage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: "/depositos",
        element: (
          <ProtectedRoute>
            <Suspense>
              <DepositosPage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: "/distribuciones",
        element: (
          <ProtectedRoute>
            <Suspense>
              <DistribucionesPage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: "/distribuciones/:id",
        element: (
          <ProtectedRoute>
            <Suspense>
              <DistribucionesDetallePage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: "/proveedores",
        element: (
          <ProtectedRoute>
            <Suspense>
              <ProveedoresPage />
            </Suspense>
          </ProtectedRoute>
        )

      },
    ],
  },
]);

export default router;
